import { Injectable } from '@angular/core';
import {
  ContentfulClientApi,
  createClient,
  Entry,
  EntryCollection,
} from 'contentful';
import { from, Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { LocalizationService } from '../localization/localization.service';

@Injectable({
  providedIn: 'root',
})
export class ContentfulService {
  private locale: Observable<string | undefined>;
  private client: ContentfulClientApi;
  private previewClient: ContentfulClientApi;
  private cfEntryCache = new Map<
    string,
    Observable<Entry<any>> | Observable<EntryCollection<any>>
  >();

  constructor(localizationService: LocalizationService) {
    this.client = createClient({
      space: environment.contentful.space,
      accessToken: environment.contentful.accessToken,
    });
    this.previewClient = createClient({
      space: environment.contentful.space,
      accessToken: environment.contentful.previewToken,
      host: 'preview.contentful.com',
    });
    this.locale = localizationService.locale;
  }

  public async getLocales() {
    const locales = await this.client.getLocales();
    return locales.items.map((item) => item.code);
  }

  public getPage<T>(page: string): Observable<Entry<T>> {
    return this.locale.pipe(
      filter((locale): locale is string => locale !== undefined),
      switchMap((locale) => this.cfEntry<T>(page, locale)),
    );
  }

  public getEntries<T>(
    contentType: string,
    limit: number,
    order,
    queryObject?: any,
  ): Observable<EntryCollection<T>> {
    return this.locale.pipe(
      filter((locale): locale is string => locale !== undefined),
      switchMap((locale) =>
        this.cfEntries<T>(contentType, limit, locale, order, queryObject || {}),
      ),
    );
  }

  public getEntryBySlug(
    contentType: string,
    slug: string,
  ): Observable<Entry<any>> {
    return this.locale.pipe(
      filter((locale): locale is string => locale !== undefined),
      switchMap((locale) => {
        return from(
          this.client.getEntries({
            content_type: contentType,
            'fields.slug': slug,
            locale,
            include: 10,
          }),
        );
      }),
      map((entries) => entries.items[0]),
    );
  }

  public getEntryById(contentType: string, id: string): Observable<Entry<any>> {
    return this.locale.pipe(
      filter((locale): locale is string => locale !== undefined),
      switchMap((locale) => {
        return from(
          this.previewClient.getEntries({
            content_type: contentType,
            'sys.id': id,
            locale,
            include: 1,
          }),
        );
      }),
      map((entries) => entries.items[0]),
    );
  }

  private cfEntry<T>(page: string, locale: string) {
    const key = `${page}::${locale}`;
    let value = this.cfEntryCache.has(key)
      ? (this.cfEntryCache.get(key) as Observable<Entry<any>>)
      : undefined;
    if (value === undefined) {
      value = from(
        this.client.getEntry<T>(page, { locale, include: 10 }),
      );
      this.cfEntryCache.set(key, value);
    }
    return value;
  }

  private cfEntries<T>(
    contentType: string,
    limit: number,
    locale: string,
    order: string,
    queryObject: any,
  ) {
    const skip = queryObject && queryObject.skip ? `:${queryObject.skip}` : '';
    const key = `${contentType}:${limit}${skip}::${locale}`;
    let value = this.cfEntryCache.has(key)
      ? (this.cfEntryCache.get(key) as Observable<EntryCollection<any>>)
      : undefined;
    if (value === undefined) {
      const query = {
        content_type: contentType,
        order,
        limit,
        locale,
        include: 10,
        ...queryObject,
      };

      value = from(this.client.getEntries<T>(query));
      this.cfEntryCache.set(key, value);
    }
    return value;
  }

  getAsset(id: string) {
    return this.client.getAsset(id);
  }
}
