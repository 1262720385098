import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import * as Chart from 'chart.js';

@Component({
  selector: 'app-results-bar-chart',
  templateUrl: './results-bar-chart.component.html',
  styleUrls: ['./results-bar-chart.component.scss'],
})
export class ResultsBarChartComponent implements OnInit, AfterViewInit {
  @Input() testResult: CalculationResult;

  public totalPercents: number[];
  public heightPercents: number[];

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.totalPercents = this.getTestResultPercents();
    this.heightPercents = this.getGeightPercents([...this.totalPercents]);
  }

  private getTestResultPercents(): number[] {
    return this.testResult.categoryCO2e.map((cat) => {
      return Math.round((cat.co2e / this.testResult.co2e) * 100);
    });
  }

  private getGeightPercents(totalPercents: number[]): number[] {
    const [largest] = [...totalPercents].sort((a, b) => b - a);
    return totalPercents.map((val) => Math.round((val / largest) * 100));
  }

  public getLabel(index: number): string {
    switch (index) {
      case 0:
        return 'Living';
      case 1:
        return 'Travel';
      case 2:
        return 'Food';
      case 3:
        return 'Shopping';
    }
  }
}
