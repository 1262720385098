import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResultsBarChartComponent } from './results-bar-chart.component';

@NgModule({
  declarations: [ResultsBarChartComponent],
  exports: [ResultsBarChartComponent],
  imports: [CommonModule],
})
export class ResultsBarChartModule {}
