<div class="bar-chart-container" *ngIf="heightPercents && totalPercents">
  <div
    class="bar"
    *ngFor="let category of testResult.categoryCO2e; let i = index"
    [ngStyle]="{ height: heightPercents[i] + '%' }"
    [ngClass]="{ largest: heightPercents[i] >= 99 }"
  >
    <span class="value">
      {{ totalPercents[i] > 0 ? totalPercents[i] : 0 }}%
    </span>
    <span class="title">
      {{ getLabel(i) }}
    </span>
  </div>
</div>
