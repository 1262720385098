import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from '../../environments/environment';
import { environment as prodEnvironment } from '../../environments/environment.prod';

type PageOrTemplateFields = PageFields | TemplateFields;

@Injectable({
  providedIn: 'root',
})
export class MetaService {
  private canonicalLink: HTMLLinkElement;

  constructor(
    private title: Title,
    private meta: Meta,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  public hideFromSearchEngines(): void {
    this.meta.updateTag({ name: 'robots', content: 'noindex' });
  }

  public updateMetaTags(entry: PageOrTemplateFields): void {
    if (entry.hideInSearchEngines) {
      this.hideFromSearchEngines();
    }

    this.meta.updateTag({
      name: 'twitter:card',
      content: 'summary_large_image',
    });
    this.updateTitle(entry.metaTitle);
    this.updateDescription(entry.metaDescription);
    this.updateImage(entry.metaImage);
    this.updateUrl(entry.slug);
    this.updateType(entry);
  }

  /**
   * Generate and append a Canonical URL link to avoid indexing
   * staging versions of compensate.com
   */
  private createCanonicalLink(slug?: string) {
    if (!this.canonicalLink) {
      this.canonicalLink = this.document.createElement('link');
      this.canonicalLink.setAttribute('rel', 'canonical');
      this.document.head.appendChild(this.canonicalLink);
    }

    this.canonicalLink.setAttribute(
      'href',
      slug ? `${prodEnvironment.host}/${slug}` : prodEnvironment.host,
    );
  }

  private updateTitle(title: string): void {
    this.title.setTitle(title);
    this.meta.updateTag({
      property: 'og:title',
      content: title,
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content: title,
    });
  }

  private isTemplateEntry(
    entry: PageOrTemplateFields,
  ): entry is TemplateFields {
    return (entry as TemplateFields).relatedTypeId !== undefined;
  }

  private updateDescription(description: string): void {
    this.meta.updateTag({
      name: 'description',
      content: description,
    });
    this.meta.updateTag({
      property: 'og:description',
      content: description,
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content: description,
    });
  }

  private updateImage(image: Asset): void {
    if (image && image.fields) {
      this.meta.updateTag({
        name: 'image',
        content: 'https:' + image.fields.file.url + '?w=1200&h=627',
      });
      this.meta.updateTag({
        property: 'og:image',
        content: 'https:' + image.fields.file.url + '?w=1200&h=627',
      });
      this.meta.updateTag({
        name: 'twitter:image',
        content: 'https:' + image.fields.file.url + '?w=1200&h=627',
      });
    }
  }

  private updateUrl(slug: string): void {
    this.createCanonicalLink(slug);

    if (slug) {
      this.meta.updateTag({
        name: 'og:url',
        content: `${environment.host}/${slug}`,
      });
    }
  }

  private updateType(entry: PageOrTemplateFields): void {
    this.meta.updateTag({
      name: 'og:type',
      content: this.getMetaContentType(
        this.isTemplateEntry(entry) ? entry.relatedTypeId : 'page',
      ),
    });
  }

  private getMetaContentType(relatedTypeId: RelatedTypeId): string {
    if (relatedTypeId === 'article') {
      return 'article';
    }

    return 'website';
  }
}
