import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject } from 'rxjs';

import { StoreService } from '../store/store.service';

const DEFAULT_LOCALE = 'en-US';

@Injectable({
  providedIn: 'root'
})
export class LocalizationService {
  public locale = new BehaviorSubject<string | undefined>(DEFAULT_LOCALE);

  constructor(
    private router: Router,
    private cookieService: CookieService,
    private storeService: StoreService
  ) {
    if (cookieService.get('locale')) {
      this.locale.next(this.cookieService.get('locale'));
    }

    this.locale.subscribe(value => cookieService.set('locale', value));
  }

  public getLocale() {
    return this.locale;
  }

  public localeToPathPrefix(locale: string | undefined) {
    return locale && locale !== DEFAULT_LOCALE ? locale : '';
  }

  public localizedPathPrefix() {
    return this.locale.pipe(map(this.localeToPathPrefix));
  }

  public async goToHome() {
    const prefix = this.localeToPathPrefix(this.locale.value);
    await this.router.navigate([prefix]);
  }

  public async goToLogin(returnUrl?: string) {
    const prefix = this.localeToPathPrefix(this.locale.value);
    if (returnUrl) {
      this.storeService.returnUrl.next(returnUrl);
    }
    await this.router.navigate([prefix, 'login']);
  }

  public async goToJoin() {
    const prefix = this.localeToPathPrefix(this.locale.value);
    await this.router.navigate([prefix, 'register']);
  }

  public async goToResult() {
    const prefix = this.localeToPathPrefix(this.locale.value);
    await this.router.navigate([prefix, 'result']);
  }

  public async goToProfile() {
    const prefix = this.localeToPathPrefix(this.locale.value);
    await this.router.navigate([prefix, 'profile']);
  }
  public async goToPayment() {
    const prefix = this.localeToPathPrefix(this.locale.value);
    await this.router.navigate([prefix, 'checkout', 'pay']);
  }

  public async navigate(page: Array<string>) {
    const prefix = this.localeToPathPrefix(this.locale.value);
    const location = [prefix].concat(page);
    await this.router.navigate(location);
  }
}
